.founder {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.founder td,
.founder th {
    border: 1px solid #ddd;
    padding: 8px;
}

.founder tr:nth-child(even) {
    background-color: #f2f2f2;
}

.founder tr:hover {
    background-color: #ddd;
}

.founder th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #eb4746;
    color: white;
}

.mt-10{
    margin-top: 5rem !important;
}
.img-box,
.img-box1,
.img-box2 {
    background-color: rgb(33 158 180 / 80%);
    box-shadow: 0 10px 12px rgb(0 0 0 / 30%), 0 15px 15px rgb(0 0 0 / 30%);
    padding: 20px 20px 10px 20px;
    color: white;
}

.img-box1 {
    border-radius: 30px 30px 30px 0px;
}

.img-box2 {
    border-radius: 0px;
}
.img-box2 p span{
    color: black;
}
.img-box {
    border-radius: 30px;
    background-color: rgb(255 255 255 / 80%);
    color: black;
}

.ul_style {
    list-style-type: circle !important;
    padding-left: 0rem !important;

}

.ul_style li {
    color: #fff;
}
.minister_card {
    border-radius: 10px;
    background-color: #fff;
    padding-bottom: 10px
}

.minister_card img{
    height: 300px;
    border-radius: 10px;
}
.minister_card span{
    font-size: 14px;
}
.img-box-rec {
    background-color: rgb(33 158 180 / 80%);
    box-shadow: 0 10px 12px rgb(0 0 0 / 30%), 0 15px 15px rgb(0 0 0 / 30%);
    padding: 20px 20px 10px 20px;
    color: white;
}


.img-box-rec {
    border-radius: 30px;
    color: black;
}

.ul_recog_style {
    list-style-type: none !important;
    padding-left: 0rem !important;
}

.ul_recog_style li {
    display: flex;
    margin-top: 10px;
}

.ul_recog_style li img {
    height: 3em;
    width: 3em;
    margin-right: 10px
}
.content {
  display: flex;
  margin: 10px;
  /* border: 1px solid red; */
  width: 100%;
}
/* @media print {
  @page {
    margin: 0;
    size: a4 !important;
  }
} */
.rightcard {
  min-width: 38%;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  /* border: 1px solid red; */
}

.leftcard {
  min-width: 44%;
  display: flex;
  /* margin-left: 10px; */
  margin-bottom: 10px;
  /* border: 1px solid red; */
}


.column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  margin-bottom: 10px;
}

.column2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px;
  margin-bottom: 10px;
}

.conceptidea {
  /* border: 1px solid red; */
  /* margin-right: 10px; */
  margin-left: 10px;
  margin-bottom: 10px;
}

.turnover {
  /* border: 1px solid red; */
  /* margin-right: 10px; */
  margin-left: 10px;
  margin-bottom: 10px;
}

.businessmodel {
  /* border: 1px solid red; */
  /* margin-right: 10px; */
  margin-left: 10px;
  margin-bottom: 10px;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,400&display=swap');
ul {
    list-style-type: none !important;
    width: inherit !important;


    /* background-color: #f1f1f1; */
}

.navbar-nav .show>.nav-link,
.navbar-nav .nav-link.active {
    color: royalblue !important;
}

ul li a {
    display: block;
    color: #fff;

    text-decoration: none;
    width: auto !important;
}
.dropdown-menu li a{
    color:black !important;
}
body {
    width: 100%;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
}

.top-header {
    font-size: 12px;
    font-weight: 500;
}

.navbar a {
    color: white;
}

.startupindia .nav-item .nav-link {
    font-size: 12px;
    
}

.startupindia li a {
    color: #fff;
    font-size: 0.8rem !important;
}

.startupindia li a:hover {
    background-color: transparent;
    border-radius: 30px;
    color: yellow;
    margin-left: 0px;
}

.login_signup {
    color: #219eb4 !important;
}

.startupindia .nav-item {
    padding: 0px 10px 0px 10px;
    text-align: left !important;
}

.startupindia .show>.nav-link,
.startupindia .nav-link.active {
    color: #fff;
}

.startupindia .nav-item .nav-link a::after {
    content: "|";
}

.navbar-toggler {
    background-color: white;
    border: 1px solid blue;
}

@media (max-width:300px) {}

#google_translate_element select{
    background:#f6edfd;
    color:#383ffa;
    border: none;
    border-radius:3px;
    padding:4px 6px
    }
    
    /*google translate link | logo */
      .goog-logo-link{
      display:none!important;
      }
    .goog-te-gadget{
    color:transparent!important;
    }
    
    /* google translate banner-frame */
    
   
    #goog-gt-tt, .goog-te-balloon-frame{display: none !important;}
   .goog-text-highlight { background: none !important; box-shadow: none !important;}
 
/* [data-column-id="Sl. No"].rdt_TableCol {
    background-color: green;
    width:10;
  } */



  .dataTables_wrapper .rdt_TableCol{
    background-color: rgb(33, 158, 180);
    border: 1px solid black;
    text-align: center;
    position: relative;
    z-index: 2000;

    
    
  }



 
  

  .dataTables_wrapper .rdt_TableCell {
    border: 1px solid black;
    

    /* padding: 4px; */
    text-align: center;
  }

  

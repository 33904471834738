@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Rajdhani:wght@500;600;700&display=swap");
.backround-ui{
    /* background: #f8f8f8; */
    padding: 4%;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1 ;
    height:100%;

}

.card-ui{
  background: linear-gradient(90deg, #FFDDCC 35%, #FFFF 0%);
  height: 100%;
  width: 90%;
  display: flex;
  margin: auto;
  /* max-width: 100%; */

  border-radius: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.textFiled-ui
{
    display: flex;
    flex-direction:column;
    margin-left: 7%;
    width: 90%;
    /* height:100% */

}
.css-b7xu7j-MuiTypography-root {
  width: 100%  !important;
}

.css-q7b8e3-MuiTypography-root{
  width: 90%  !important;
}

.textFiled-ui-reg{
 
  width: 700px;
  margin-left: -10%;

}

.formFounder{
  background: white;
  height: 100vh;
  width: 710px;
  display: flex;
  margin: auto;
  max-width: 100%;
  /* border: 2px solid; */
  padding: 10px;
  /* box-shadow: 2px 5px 5px 5px #888888; */
  border-radius: 5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* margin-left: 50px ; */



}
ul {
    list-style-type: none;
    margin: 8%;  /*changed to 40px*/ 
    padding: 0;
  
    /* background-color: #f1f1f1; */
  }


  .scrol  { 
    font: bold 14px Arial; 
    /* border:1px solid black;  */
    width:100% ;
    height: 500px; 
    color:#616D7E; 
    overflow:scroll; 
    overflow-y:scroll;
    overflow-x:hidden;
    margin-top: 20px;
    }
    .footer
    {
      margin-top: 350px !important;
    }
    .formHeadLineLeft{
      display: flex;
      justify-content: flex-start;
    font-family: 'Poppins', sans-serif;

    }
    
   
    .logo{
      /* display: flex;
      justify-content: center; */
      width: 90%;
      height: 100%;
      margin-top: 10px;

    }

    table, td, th {
      border: 5px solid black;
    }
    
    table {
      border-collapse: collapse;
      width: 100%;
    }
    hr{
      margin-left: 50px;
    }

    .footerHeading{
      margin-top:10px;
      font-size:13px;
      color:"gray";
    }

    .textFiledMargin
    {
      margin-left: 50px;
    }
    .Button{
      background:"#4D35E5"
    }

    /* Mihir */
    .design_develop_note{
      margin-top: 100px !important;
    }

    .profile{
     background-color: #fff; 
      border-radius: 10px;
      width: auto;
      display: flex;
      width:50%;
      height:55px;
      margin-left: 250%;
      margin-top: -140px; 
    }
    .profile h4{
      margin-left: 5px;
      margin-right: 10px;
      color: black;
    }

    .logout_hover:hover{
      background-color: rgb(247, 247, 247);
      border-radius: 0px;
    }

    /* Pre Reg. Yes / No */
    .pre_reg_menu_item{
      width:647px;
    }

.twitter-timeline {
    height: 400px !important;
}

.css-1dbjc4n{
    height: 400px !important;
}
.css-1dbjc4n {

}
.banner-data {
    position: absolute;
    align-items: center;
    display:flex;
    /* width: 23%; */
    /* height:6%; */
    right: 35%;
    top: 26%;    
    background-color: #DDDDDD;
    padding: 1rem;
    border-radius: 10px;
}

.ministers_data {
    padding-top: 6%;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100.9%;
}

.tab-content .active {
    border-right: 0px solid white !important;
    background: rgb(255, 255, 255) !important;
}

.navbar-nav li .active {
    background-color: transparent !important;
    border-radius: 30px;
    color: white;
}

.navbar-nav li a:hover {
    background-color: transparent;
    border-radius: 30px;
    color: #6A9BF4;
    margin-left: 0px;
}

.text__left {
    text-align: left !important;
}

.fa-2x {
    font-size: 1.5em;
}


.glance-data h6 {
    font-weight: 700
}

.glance-data p {
    font-size: 13px;
}

.glance-icon {
    text-align: left !important;
    margin: 10px;
}

.glance-icon span {
    font-size: 13px;
}

.glance-icon-border {
    border-right: 1px dashed #FFB15C;
    margin-right: 20px;
}

.color1 {
    color: #6A9BF4;
}

.bgcolor1 {
    background-color: #6A9BF4;
}

.color2 {
    color: #FFB15C;
}

.bgcolor2 {
    background-color: #FFB15C;
}

.color3 {
    color: #F7527A;
}

.bgcolor3 {
    background-color: #F7527A;
}

.color4 {
    color: #10249F;
}

.bgcolor4 {
    background-color: #10249F;
}

.profile-card {
    letter-spacing: 0.025em;
    text-align: center;
    color: #002D40;
}

.profile-card .cover {
    background-image: url("https://source.unsplash.com/WLUHO9A_xik/1600x900"), linear-gradient(135deg, rgba(26, 166, 183, 0.2), rgba(255, 65, 77, 0.4));
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    height: 14rem;
    width: 100%;
    border-radius: 2rem;
    margin-bottom: -14rem;
    transform: translateY(0);
    transition: transform 500ms ease-in-out, opacity 200ms 300ms;
    opacity: 0;
    box-shadow: 0 2rem 4rem -3rem rgba(0, 45, 64, 0.5), 0 0rem 8rem 0 rgba(0, 45, 64, 0.1);
}

.profile-card .pic {
    background-image: url(""), linear-gradient(135deg, rgba(26, 166, 183, 0.1), rgba(255, 65, 77, 0.2));
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    position: absolute;
    left: 50%;
    width: 8rem;
    height: 10rem;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    margin-top: -2rem;
    z-index: 2;
    box-shadow: 0 1rem 1.5rem -1rem rgba(0, 45, 64, 0.2), 0 0 0.5rem 0 rgba(0, 45, 64, 0.2), 0 0rem 4rem 0 rgba(0, 0, 0, 0.2);
}

.profile-card .profile {
    position: relative;
    background: #16C995;
    width: 80%;
    border-radius: 10px;
    padding-top: 2rem;
    padding: 1rem;
    box-sizing: border-box;
    transition: transform 500ms ease-in-out;
    box-shadow: 0 2rem 4rem -3rem rgba(0, 45, 64, 0.5), 0 0rem 8rem 0 rgba(0, 45, 64, 0.1);
}

.profile-card .above-fold {
    padding-top: 3rem;
}

.profile-card .above-fold .name {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.2rem;
    margin-top: 1rem;
    color: #fff
}

.profile-card .above-fold .role {
    font-size: 0.8rem;
    color: #fff;
    font-weight: 400;
    margin-bottom: 1rem;
}

.profile-card .above-fold .location {
    font-size: 1.2em;
    font-weight: 200;
    margin-bottom: 0.5rem;
    opacity: 0.6;
}

.profile-card .above-fold .location i {
    margin-right: 0.5rem;
}

.profile-card .below-fold {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 500ms ease-in-out, opacity 500ms 250ms;
}

.profile-card .below-fold .stats {
    border-top: 1px solid rgba(0, 45, 64, 0.1);
    margin-top: 1.5rem;
}

.profile-card .below-fold .stat {
    flex-basis: 4rem;
    flex-grow: 2;
    padding: 1rem 0;
    border-right: 1px solid rgba(0, 45, 64, 0.1);
    margin-right: 1px;
    cursor: pointer;
    background: rgba(217, 236, 242, 0.2);
    transition: background 200ms;
}

.profile-card .below-fold .stat:hover {
    background: rgba(217, 236, 242, 0.4);
}

.profile-card .below-fold .stat:last-child {
    border: none;
}

.profile-card .below-fold .stat label {
    font-weight: 400;
    opacity: 0.4;
}

.profile-card .below-fold .stat .num {
    font-weight: 700;
    font-size: 2rem;
    color: #1AA6B7;
    margin: 0.5rem;
}

.profile-card .below-fold .about {
    margin: -1rem 0 2rem;
    padding: 0 2rem;
    text-align: left;
}

.profile-card .below-fold .about p {
    font-weight: 300;
    line-height: 1.5rem;
}

.profile-card.expand .cover {
    opacity: 1;
    transform: translateY(-5rem);
    transition: transform 500ms ease-in-out, opacity 200ms 0ms;
}

.profile-card.expand .profile {
    transform: translateY(5rem);
}

.profile-card.expand .below-fold {
    max-height: 19em;
    opacity: 1;
}

.profile-card.expand #expand-button {
    animation: none;
    transform: rotate(180deg);
}

.row {
    display: flex;
    justify-content: center;
}

.button {
    width: 9rem;
    height: 2rem;
    padding: 1.2rem;
    margin: 1rem 0.5rem 0;
    box-sizing: border-box;
    background: transparent;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 0;
    text-transform: upper;
    font-weight: 500;
    color: #F56A79;
    border: 2px solid #F56A79;
    border-radius: 2rem;
    transition: background 200ms, color 200ms;
}

.button:hover {
    background: #F56A79;
    color: white;
}

#expand-button {
    opacity: 0.3;
    font-size: 1.5rem;
    margin: 1rem auto;
    animation: bounce 4s infinite;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: background 200ms, color 200ms, transform 500ms;
}

#expand-button i {
    height: 0;
    margin-top: 0.5rem;
}

#expand-button:hover {
    background: #002D40;
    color: white;
}

@keyframes bounce {

    0%,
    60%,
    100% {
        transform: translatey(0);
    }

    80% {
        transform: translatey(-8px);
    }

    90% {
        transform: translatey(8px);
    }
}

.heading {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}

.shadow {}

.heading span {
    border-left: 10px solid #ffdb0a;
    padding-left: 10px;

}

#highlight .nav .nav-item {
    background-color: #eee;
    width: 24%;
    margin: 2px;
}

.nav_ul {
    margin-left: 0px !important;
    width: 100% !important;
    padding: 2px;
}

.nav_ul .nav-item {
    width: 50% !important;
}

.nav_ul .nav-item {
    width: 50% !important;
}

.nav_ul .nav-item .nav-link {
    color: #219eb4 !important;
}

.notification-event {
    list-style-type: square !important;
    padding-left: 0px !important;
}

.notification-event li a {
    display: block;
    color: #000 !important;
    text-decoration: none;
    width: auto !important;
    padding: 8px 0px !important;
}

.notification-event li a:hover {
    color: #10249F !important;
    background-color: #fff !important;
}

.notification-event li {
    text-align: left !important;
    font-size: 14px;
    font-weight: bold;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #219eb4 !important;
}

.highlight-card {
    border: 1px solid darkgray;
    font-size: 12px;
    border-radius: 10px
}

.highlight-card h6 {
    font-size: 13px;
}

.highlight-card button {
    font-size: 10px;
}

.connect-with-us .fa {
    font-size: 20px;
}
.connect-with-us a{
    color: #fff !important;
}
.connect-with-us a:hover{
    color: #ffdb0a !important;
}
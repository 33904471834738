@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,400&display=swap');

ul {
    list-style-type: none !important;
    width: inherit !important;


    /* background-color: #f1f1f1; */
}

ul li a {
    display: block;
    color: #fff;

    text-decoration: none;
    width: auto !important;
}

body {
    width: 100%;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
}

.top-header {
    font-size: 12px;
    font-weight: 500;
}

.navbar a {
    color: white;
}

.startupindia .nav-item .nav-link {
    font-size: 12px;
}

.startupindia li a {
    color: #fff;
    font-size: 0.8rem !important;
}

.startupindia li a:hover {
    background-color: transparent;
    border-radius: 30px;
    color: yellow;
    margin-left: 0px;
}

.login_signup {
    color: black !important;
}

.startupindia .nav-item {
    padding: 0px 10px 0px 10px;
    text-align: left !important;
}

.startupindia .show>.nav-link,
.startupindia .nav-link.active {
    color: white;
}

.startupindia .nav-item .nav-link a::after {
    content: "|";
   
}

.navbar-toggler {
    background-color: white;
    border: 1px solid blue;
}

@media (max-width:500px) {}
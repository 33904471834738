@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Rajdhani:wght@500;600;700&display=swap");
.btn_class{
  background-color: darkblue !important;
  margin-top: 20px;
  color: #fff !important;
}
.bg {
  /* background: #f8f8f8; */
  padding: 4%;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  height: 100%;
}


.form-ui11 {
  background: #ffddcc;
  height: 100%;
  width: 90%;
  display: flex;
  margin: auto;
  max-width: 100%;
  /* border: 2px solid; */
  padding: 10px;
  /* box-shadow: 2px 5px 5px 5px #888888; */
  border-radius: 15;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.form-ui1 {
  background: linear-gradient(90deg, #ffddcc 35%, #ffff 0%);
  /* background: linear-gradient(90deg, teal 35%, #FFFF 0%); */
  /* background: linear-gradient(90deg, rgb(0, 185, 185) 35%, #FFFF 0%); */
  width: 90%;
  display: flex;
  margin: auto;
  max-width: 100%;
  /* border: 2px solid; */
  /* padding: 15px; */
  /* box-shadow: 2px 5px 5px 5px #888888; */
  border-radius: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.form-ui2 {
  background: linear-gradient(90deg, #219eb4 35%, #ffff 0%);
  /* background: linear-gradient(90deg, teal 35%, #FFFF 0%); */
  /* background: linear-gradient(90deg, rgb(0, 185, 185) 35%, #FFFF 0%); */
  width: 100%;
  margin: auto;
  max-width: 100%;
  /* border: 2px solid; */
  /* padding: 15px; */
  /* box-shadow: 2px 5px 5px 5px #888888; */
  border-radius: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


.form-ui_department_login {
  background: white;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  max-width: 100%;
  border-radius: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.formFounder {
  background: white;
  height: 100%;
  width: 710px;
  display: flex;
  margin: auto;
  max-width: 100%;
  /* border: 2px solid; */
  padding: 10px;
  /* box-shadow: 2px 5px 5px 5px #888888; */
  border-radius: 5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* margin-left: 50px ; */
}

/* .blink {
  animation: blink 2s steps(1, end) infinite;
} */


ul {
  list-style-type: none;
  margin: 40px;
  /*changed to 40px*/
  padding: 0;
  width: 300px;
  margin-top: 100px;
  /* background-color: #f1f1f1; */
}

ul li {
  color: #030e12;
}

li a {
  display: block;
  color: #030e12;
  padding: 8px 16px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  width: 300px;
}

/* Change the link color on hover */
li a:hover {
  background-color: #eb4747;
  border-radius: 30px;
  color: black;
  margin-left: 0px;
}

li .active {
  background-color: #eb4747;
  border-radius: 30px;
  color: white;
}

#incubator li .active {
  background-color: #fff;
  border-radius: 30px;
  color: white;
}

#incubator li a:hover {
  background-color: #fff;
  border-radius: 30px;
  color: black;
  margin-left: 0px;
}

.blink {
  animation: blink 2s steps(1, end) infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}


.scrol {
  font: bold 14px Arial;
  /* border:1px solid black;  */
  width: 100%;
  height: 500px;
  color: #616d7e;
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 20px;
}

.footer {
  margin-top: 350px !important;
}

.headLineLeft {
  display: flex;
  justify-content: flex-start;
  font-family: "Poppins", sans-serif;
}

.logo {
  /* display: flex;
      justify-content: center; */
  width: 300px;
  height: 70px;
  margin-top: 10px;
}

table,
td,
th {
  border: 1px solid black;
}

table {
  border-collapse: collapse;
  width: 100%;
}

hr {
  margin-left: 50px;
}

.footerHeading {
  margin-top: 10px;
  font-size: 13px;
  color: "gray";
}

.textFiledMargin {
  margin-left: 50px;
}

.Button {
  background: "#4D35E5";
}

/* Mihir */
.design_develop_note {
  margin-top: 100px !important;
}

.profile{
  background-color: #fff; 
   border-radius: 10px;
   width: auto;
   display: flex;
   width:50%;
   height:55px;
   margin-left: 250%;
   margin-top: -140px; 
 }

.profile h4 {
  margin-left: 5px;
  margin-right: 10px;
  color: black;
}
/* @media (min-width:320px)  {
  .profile h4 {
    margin-left: 5px;
    margin-right: 10px;
    color: black;
    margin-top: 110%;
  }
}
@media (min-width:480px) {
  .profile h4 {
    margin-left: 5px;
    margin-right: 10px;
    color: black;
    margin-top: 110%;
  }
}
@media (min-width:600px){
  .profile h4 {
    margin-left: 5px;
    margin-right: 10px;
    color: black;
    margin-top: 110%;
  }
}  
@media (min-width:801px)  {
  .profile h4 {
    margin-left: 5px;
    margin-right: 10px;
    color: black;
    margin-top: 110%;
  }
}
@media (min-width:1025px) {
  .profile h4 {
    margin-left: 5px;
    margin-right: 10px;
    color: black;
    margin-top: 110%;
  }
}
@media (min-width:1281px){
  .profile h4 {
    margin-left: 5px;
    margin-right: 10px;
    color: black;
    margin-top: 110%;
  }
} */

.profile_incubator {
  background-color: red !important;
  border-radius: 10px;
  width: auto;
  display: flex;
  width: auto;
  height: 55px;
  margin-left: 1060px;
  margin-top: -140px;
}

.profile_incubator h4 {
  margin-left: 5px;
  margin-right: 10px;
  color: black;
}

.logout_hover:hover {
  background-color: rgb(247, 247, 247);
  border-radius: 0px;
}

/* Pre Reg. Yes / No */
.pre_reg_menu_item {
  width: 647px;
}

/* BASIC DETAILS PAGE */
.main_box {
  /* background-color: lightblue; */
  width: 100%;
  /* margin:5%; */
}





.main_box_incubator {
  /* background-color: lightblue; */
  width: 100%;
}

.sub_box {
  width: 100%;
  /* margin: 5%; */
  /* background-color: aquamarine; */
}

.sub_box_entity {
  width: 90%;
  margin: 5%;
  /* background-color: aquamarine; */
}

.sub_box2 {
  width: 100%;
  margin: 4%;
  /* background-color: aquamarine; */
}

.sub_box_incubator {
  width: 90%;
  margin: 43px;
  /* background-color: aquamarine; */
}

.box_heading {
  color: #eb4747;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 2px solid #eb4747;
  font-weight: bold;
}

.box_heading_incubator {
  color: darkblue;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 2px solid darkblue;
  font-weight: bold;
}

.page_design_develop {
  margin-left: -1200px;
  padding-bottom: 30px;
}

.page_design_develop_incu {
  margin-left: -70em;
  padding-bottom: 30px;
}

.logout_hover:hover {
  background-color: rgb(223, 223, 223);
}


.view-file {
  text-align: left !important;
  text-decoration: underline;
}

.disable {
  /* pointer-events: none;
  opacity: 0.6; */
  
}

.basic-single{

  text-align: left;
  background: rgb(223, 223, 223);
  z-index: 1000;
}


.cylinder,.cylinder_left {
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	width: 300px;
	border-radius: 25px/50px;
	background-color: #00C2CB;
	padding: 10px;
	font-size: 14px;
}

.cylinder:before,
.cylinder_left:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 10%;
	height: 100%;
	border-radius: 25px/50px;
	background-color: #03989E;
	content: '';

}

.cylinder_left:before {
	position: absolute;
}

.cylinder:after,
.cylinder_left:after {
	position: absolute;
	
	width: 10%;
	height: 100%;
	border-radius: 25px/50px;
	background-color: #03989E;
	content: '';
}
.cylinder_left:after {
	position: absolute;
	
}
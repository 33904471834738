.App {
  text-align: center;
  background-image: url('../src/Container/CarouselContainer/2807771_18146.jpg');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card-ui {
  height: 100% !important;
}

.avatar-style {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: -24px;
}

/* Sidebar */

.main-container {
  display: flex;
}

main {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.sidebar {
  background: rgb(0, 7, 61);
  color: white;
  height: 100%;
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 5px; */
  /* border: 1px solid red; */
}

.logo {
  font-size: 18px;
  /* line-height: 0; */
}

.bars {
  width: 100px;
  /* border: 1px solid red; */
  margin-bottom: 20px;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
}

.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active_link {
  
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
  font-family: "Poppins", sans-serif !important;

}

table,
th,
td {
  border: 1px solid black !important;
  font-family: "Poppins", sans-serif !important;

}

td,
th {
  padding: 1px;
}
